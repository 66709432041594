import React from 'react'
import './header.css';
import Logo from './Logo';
import Nav from './Nav';

// import SearchBar from './SearchBar';

export default function Header() {
  return (
<header id='header' className='header fixed-top d-flex align-items-centre' >
{/* logo */}
<Logo/>
{/* Seacrhbar */}
{/* <SearchBar/> */}
{/* nav */}
<Nav/>
</header>
  )
}

