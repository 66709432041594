// MonthlyPnlModal.js
import React from 'react';
import './MonthlyPnlModal.css';

const MonthlyPnlModal = ({ isVisible, onClose, monthlyPnlData, summaryData }) => {
  if (!isVisible) return null;

  return (
    <div className="month-modal-overlay">
      <div className="month-modal-content">
        <div className="month-modal-header">
          <h2>Monthly P&L</h2>
          <button className="month-close" onClick={onClose} title="Close Modal">&times;</button>
        </div>
        
        {/* Display the summary data */}
        <div className="summary">
          <h3>Summary</h3>
          <p><strong>Total Monthly P&L:</strong> {summaryData.totalMonthlyPnl}</p>
          <p><strong>Overall Profit Percent:</strong> {summaryData.overallProfitPercent}%</p>
          <p><strong>Total Sharing:</strong> {summaryData.totalSharing > 0 ? summaryData.totalSharing : 0}</p>
          <p><strong>Total Capital:</strong> {summaryData.totalCapital}</p>
        </div>

        {/* Table for monthly P&L list with scroll */}
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Client</th>
                <th>P&L</th>
                <th>Profit Percent</th>
                <th>Sharing</th>
                <th>Capital</th>
              </tr>
            </thead>
            <tbody>
              {monthlyPnlData.map((item, index) => (
                <tr key={index}>
                  <td>{item.client}</td>
                  <td>{item.pnl}</td>
                  <td>{item.profitPercent}%</td>
                  <td>{item.sharing}</td>
                  <td>{item.capital}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  
  );
};

export default MonthlyPnlModal;
