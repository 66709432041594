import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import SearchBar from "./SearchBar";
import Table from "./Table";

const ReportContainerWithTable = () => {
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [setSelectedClient] = useState(null);
  const [setDrawdownData] = useState(null);

  useEffect(() => {
    fetch("https://www.quatdashboard.com:8084/api/mainTable/all")
      .then((response) => response.json())
      .then((data) => {
        const fetchDrawdownsForClients = data.map(async (client) => {
          const drawdownResponse = await fetch(
            `https://www.quatdashboard.com:8084/api/clientPerformance/client/${client.clientid}/drawdowns`
          );
          const drawdownData = await drawdownResponse.json();

          return {
            ...client,
            currentDrawdown: drawdownData.currentDrawdown || 0,
            maxDrawdown: drawdownData.maxDrawdown || 0,
            cummulativeRoi: drawdownData.cummulativeRoi || 0,
          };
        });

        Promise.all(fetchDrawdownsForClients).then((clientsWithDrawdowns) => {
          setRows(clientsWithDrawdowns);
          setLoading(false);
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  const handlePerformanceClick = async (client) => {
    setSelectedClient(client);
    setDrawdownData(null); // Reset the drawdown data

    // Fetch yearly data for this client by default
    fetchYearlyData(client.clientid);
  };

  const fetchYearlyData = async (clientId) => {
    try {
      const response = await fetch(
        `https://www.quatdashboard.com:8084/api/clientPerformance/yearly/${clientId}`
      );
      const data = await response.json();
      setDrawdownData(data); // Store data in state
    } catch (error) {
      console.error("Error fetching yearly drawdown data:", error);
    }
  };

  const filteredRows = rows.filter((row) =>
    row.name.toLowerCase().includes(searchText.toLowerCase())
  );

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      {/* Search Bar */}
      <SearchBar searchText={searchText} setSearchText={setSearchText} />

      {/* Table */}
      <Table
        rows={filteredRows}
        searchText={searchText}
        onPerformanceClick={handlePerformanceClick}
      />
    </Box>
  );
};

export default ReportContainerWithTable;
