import React from 'react';

function StatusFilter({ filterChange }) {
    return (
        <div className="filter">
            <a className="icon" href="#" data-bs-toggle="dropdown">
                <i className="bi bi-three-dots"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                <li className="dropdown-header text-start">
                    Status Filter
                </li>
                <li>
                    <button className="dropdown-item" onClick={() => filterChange('all')}>
                        All
                    </button>
                </li>
                <li>
                    <button className="dropdown-item" onClick={() => filterChange('success')}>
                        Success
                    </button>
                </li>
                <li>
                    <button className="dropdown-item" onClick={() => filterChange('failed')}>
                        Failed
                    </button>
                </li>
            </ul>
        </div>
    );
}

export default StatusFilter;
