import React from "react";
import PageTitle from "../Dashboard/PageTitle";
import "../Dashboard/main.css";
// import Invoices from "./Invoices";
import Paymentcard from "./Paymentcards";

function Paymentmain() {
  return (
    <main id="main" className="main">
      <PageTitle page={"Payment"} />
      <Paymentcard />
    </main>
  );
}

export default Paymentmain;
