import React, { useState, useEffect } from "react";
import "./ordermodal.css"; // Add styling for your modal

function OrderModal({ clientID, onClose }) {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (clientID) {
      fetch(
        `https://www.quatdashboard.com:8084/api/checkOrders?clientID=${clientID}`
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch orders");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Parsed Order Response Data:", data); // Log parsed order response data

          let extractedData = [];

          // Check if it's a Flattrade response (Array)
          if (Array.isArray(data)) {
            // Flattrade response handling
            extractedData = data.map((order, index) => ({
              sno: index + 1,
              symbol: order.tsym,
              product: order.s_prdt_ali || order.prd,
              type: order.trantype === "S" ? "SELL" : "BUY", // Convert 'S' to 'SELL' and anything else to 'BUY'
              quantity: order.fillshares || order.qty,
              average_price: order.avgprc,
              status: order.status,
              time: order.exch_tm, // Using exchange time
            }));
          }
          // Zerodha response (Object with status field)
          else if (data.status === "success") {
            extractedData = data.data.reverse().map((order, index) => ({
              sno: index + 1,
              symbol: order.tradingsymbol,
              product: order.product,
              type: order.transaction_type,
              quantity: order.filled_quantity || order.quantity,
              average_price: order.average_price,
              status: order.status,
              time: order.order_timestamp, // Using order timestamp
            }));
          }
          // Tradier response (Object with orders field)
          else if (data.orders && Array.isArray(data.orders.order)) {
            extractedData = data.orders.order.reverse().map((order, index) => ({
              sno: index + 1,
              symbol: order.option_symbol,
              product: order.class, // Using 'class' as product (can be adjusted based on your needs)
              type: order.side === "buy_to_open" ? "BUY" : "SELL", // Convert 'buy' to 'BUY' and 'sell' to 'SELL'
              quantity: order.quantity,
              average_price: order.avg_fill_price,
              status: order.status,
              time: order.transaction_date, // Using transaction date
            }));
          } else {
            console.error("Unexpected response format or error:", data);
            setOrders([]);
            setLoading(false);
            return;
          }

          setOrders(extractedData);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching orders:", error);
          alert("Error fetching orders");
          setLoading(false);
        });
    }
  }, [clientID]);

  if (loading) {
    return <div className="modal">Loading orders...</div>;
  }

  return (
    <div className="modal">
      <div className="ord-modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h2>Orders for Client ID: {clientID}</h2>
        {orders.length > 0 ? (
          <table className="orders-table">
            <thead>
              <tr>
                <th>Symbol</th>
                <th>Product</th>
                <th>Type</th>
                <th>Quantity</th>
                <th>Avg. Price</th>
                <th>Status</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => (
                <tr key={index}>
                  <td>{order.symbol}</td>
                  <td>{order.product}</td>
                  <td>{order.type}</td>
                  <td>{order.quantity}</td>
                  <td>{order.average_price}</td>
                  <td>{order.status}</td>
                  <td>{order.time}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No orders available.</p>
        )}
      </div>
    </div>
  );
}

export default OrderModal;
