import React, { useState } from "react";
import "./userpage.css";
import UserFormModal from "./UserFormModal";
import UserTable from "./UserTable";
import ZeroPackageModal from "./ZeroPackageModal";
import Zeroindex from "./Zeroindex";
import ListPackageModal from "./ListPackageModal";
import MonthlyPnlModal from "./MonthlyPnlModal"; // Import the new modal
import PnlModal from "./PnlModal"; // Import the P&L modal

function UserPage() {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isPackageModalVisible, setPackageModalVisible] = useState(false);
  const [isIndexModalVisible, setIndexModalVisible] = useState(false);
  const [isListPackageModalVisible, setListPackageModalVisible] =
    useState(false);
  const [isMonthlyPnlModalVisible, setMonthlyPnlModalVisible] = useState(false); // State for Monthly P&L modal
  const [isPnlModalVisible, setPnlModalVisible] = useState(false); // P&L modal state
  const [filters, setFilters] = useState({
    priority: "all",
    broker: "all",
    server: "all",
    status: "all",
  });
  const [packageData, setPackageData] = useState(null);
  const [monthlyPnlData, setMonthlyPnlData] = useState([]); // State to hold monthly P&L data
  const [summaryData, setSummaryData] = useState({}); // State to hold summary data
  const [pnlData, setPnlData] = useState([]); // State for holding P&L data

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleOpenPackageModal = () => {
    setPackageModalVisible(true);
  };

  const handleClosePackageModal = () => {
    setPackageModalVisible(false);
  };

  const handleOpenIndexModal = () => {
    setIndexModalVisible(true);
  };

  const handleCloseIndexModal = () => {
    setIndexModalVisible(false);
  };

  const handleOpenListModal = async () => {
    try {
      const response = await fetch(
        "https://www.quatdashboard.com:8084/api/mainTable/listpackages"
      );
      const data = await response.json();
      setPackageData(data);
      setListPackageModalVisible(true);
    } catch (error) {
      console.error("Error fetching package data:", error);
    }
  };

  const handleCloseListModal = () => {
    setListPackageModalVisible(false);
  };

  const handleOpenMonthlyPnlModal = async () => {
    // Fetch the monthly P&L data from the backend
    try {
      const response = await fetch(
        "https://www.quatdashboard.com:8084/api/mainTable/listmonthlypnl"
      ); // Backend endpoint
      const data = await response.json();
      setMonthlyPnlData(data.monthlyPnlList); // Set the fetched data
      setSummaryData({
        // Set summary data
        totalMonthlyPnl: data.totalMonthlyPnl,
        overallProfitPercent: data.overallProfitPercent,
        totalSharing: data.totalSharing,
        totalCapital: data.totalCapital,
      });
      setMonthlyPnlModalVisible(true);
    } catch (error) {
      console.error("Error fetching monthly P&L data:", error);
    }
  };

  const handleOpenMonthlyUSPnlModal = async () => {
    // Fetch the monthly P&L data from the backend
    try {
      const response = await fetch(
        "https://www.quatdashboard.com:8084/api/mainTable/listmonthlyuspnl"
      ); // Backend endpoint
      const data = await response.json();
      setMonthlyPnlData(data.monthlyPnlList); // Set the fetched data
      setSummaryData({
        // Set summary data
        totalMonthlyPnl: data.totalMonthlyPnl,
        overallProfitPercent: data.overallProfitPercent,
        totalSharing: data.totalSharing,
        totalCapital: data.totalCapital,
      });
      setMonthlyPnlModalVisible(true);
    } catch (error) {
      console.error("Error fetching monthly P&L data:", error);
    }
  };

  const handleCloseMonthlyPnlModal = () => {
    setMonthlyPnlModalVisible(false);
  };

  const handleOpenPnlModal = async () => {
    try {
      const response = await fetch(
        "https://www.quatdashboard.com:8084/api/mainTable/getpnl"
      ); // Replace with your backend endpoint
      const data = await response.json();
      setPnlData(data);
      setPnlModalVisible(true);
    } catch (error) {
      console.error("Error fetching P&L data:", error);
    }
  };

  const handleOpenUSPnlModal = async () => {
    try {
      const response = await fetch(
        "https://www.quatdashboard.com:8084/api/mainTable/getuspnl"
      ); // Replace with your backend endpoint
      const data = await response.json();
      setPnlData(data);
      setPnlModalVisible(true);
    } catch (error) {
      console.error("Error fetching P&L data:", error);
    }
  };

  const handleClosePnlModal = () => {
    setPnlModalVisible(false);
  };

  const handlePackageAction = (action) => {
    alert(`Performing ${action} action`);
    handleClosePackageModal();
  };

  const handleIndexAction = (action) => {
    alert(`Performing ${action} action`);
    handleCloseIndexModal();
  };

  const handleFilterChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  return (
    <section className="dashboard section">
      <div className="row full-width">
        <div className="col-12">
          <div
            className="content"
            style={{ padding: "20px", borderRadius: "5px" }}
          >
            <button className="btn-create-user" onClick={handleOpenModal}>
              Create User
            </button>
            <button
              className="btn-packages"
              onClick={handleOpenPackageModal}
              style={{ marginLeft: "20px" }}
            >
              Packages
            </button>
            <button
              className="btn-indexpackage"
              onClick={handleOpenIndexModal}
              style={{ marginLeft: "20px" }}
            >
              Index
            </button>
            <button
              className="btn-listpackage"
              onClick={handleOpenListModal}
              style={{ marginLeft: "20px" }}
            >
              List Packages
            </button>
            {/* <button className="btn-viewpnl" onClick={handleOpenListModal} style={{ marginLeft: '20px' }}>View P&L</button> */}
            <button
              className="btn-viewpnl"
              onClick={handleOpenPnlModal}
              style={{ marginLeft: "20px" }}
            >
              View P&L
            </button>

            <button
              className="btn-viewpnl"
              onClick={handleOpenUSPnlModal}
              style={{ marginLeft: "20px" }}
            >
              View US P&L
            </button>
            <button
              className="btn-monthlypnl"
              onClick={handleOpenMonthlyPnlModal}
              style={{ marginLeft: "20px" }}
            >
              Monthly P&L
            </button>
            <button
              className="btn-monthlypnl"
              onClick={handleOpenMonthlyUSPnlModal}
              style={{ marginLeft: "20px" }}
            >
              Monthly US P&L
            </button>
          </div>
          <div className="content-table" style={{ marginTop: "20px" }}>
            <UserTable filters={filters} onFilterChange={handleFilterChange} />
          </div>
        </div>
      </div>
      <UserFormModal isVisible={isModalVisible} onClose={handleCloseModal} />
      <ZeroPackageModal
        isVisible={isPackageModalVisible}
        onClose={handleClosePackageModal}
        onPackageAction={handlePackageAction}
      />
      <Zeroindex
        isVisible={isIndexModalVisible}
        onClose={handleCloseIndexModal}
        onPackageAction={handleIndexAction}
      />
      <ListPackageModal
        isVisible={isListPackageModalVisible}
        onClose={handleCloseListModal}
        packageData={packageData}
      />
      <MonthlyPnlModal
        isVisible={isMonthlyPnlModalVisible}
        onClose={handleCloseMonthlyPnlModal}
        monthlyPnlData={monthlyPnlData} // Pass the monthly P&L data to the modal
        summaryData={summaryData} // Pass the summary data to the modal
      />
      <PnlModal
        isVisible={isPnlModalVisible}
        onClose={handleClosePnlModal}
        pnlData={pnlData}
      />
    </section>
  );
}

export default UserPage;
