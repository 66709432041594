import React from 'react';
import './listpackagemodal.css';

function ListPackageModal({ isVisible, onClose, packageData }) {
  if (!isVisible) return null;

  return (
    <div className="list-modal">
      <div className="list-modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>Package Details</h2>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Client ID</th>
                <th>Nifty</th>
                <th>Banknifty</th>
                <th>Finnifty</th>
                <th>Midcap</th>
                <th>Sensex</th>
                <th>Bankex</th>
              </tr>
            </thead>
            <tbody>
              {packageData && packageData.map((pkg, index) => (
                <tr key={index}>
                  <td>{pkg.clientId} ({pkg.clientName})</td>
                  <td>{pkg.niftySum}</td>
                  <td>{pkg.bankniftySum}</td>
                  <td>{pkg.finniftySum}</td>
                  <td>{pkg.midcapSum}</td>
                  <td>{pkg.sensexSum}</td>
                  <td>{pkg.bankexSum}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ListPackageModal;
