import React, { useState } from 'react';
import './cardfilter.css'; // Assuming you're adding custom styling here

function CardFilter({ filterChange }) {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // Get the current date in YYYY-MM-DD format
  const today = new Date().toISOString().split('T')[0];

  const handleCustomRange = () => {
    if (startDate && endDate) {
      filterChange({ type: 'custom', startDate, endDate });
    }
  };

  return (
    <div className="filter">
      <a className="icon" href="" data-bs-toggle="dropdown">
        <i className="bi bi-three-dots"></i>
      </a>
      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
        <li>
          <a className="dropdown-item" onClick={() => filterChange('Today')}>
            Today
          </a>
        </li>
        {/* <li>
          <a className="dropdown-item" onClick={() => filterChange('This Month')}>
            This Month
          </a>
        </li>
        <li>
          <a className="dropdown-item" onClick={() => filterChange('This Year')}>
            This Year
          </a>
        </li> */}
        <li>
          <hr className="dropdown-divider" />
        </li>
        <li className="dropdown-item">
          <label htmlFor="start-date">Start Date:</label>
          <input
            type="date"
            id="start-date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            max={today} // Prevent selecting future dates
            className="date-picker"
          />
        </li>
        <li className="dropdown-item">
          <label htmlFor="end-date">End Date:</label>
          <input
            type="date"
            id="end-date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            max={today} // Prevent selecting future dates
            className="date-picker"
          />
        </li>
        <li>
          <button className="dropdown-item apply-button" onClick={handleCustomRange}>
            Apply
          </button>
        </li>
      </ul>
    </div>
  );
}

export default CardFilter;
