import React, { useState } from "react";
import { Button, CircularProgress } from "@mui/material";

const PerformanceTab = ({ row }) => {
  const [loading, setLoading] = useState(false);


  const customEncodeClientId1 = (clientId) => {
    const base64Encoded = btoa(clientId);
    const reversed = base64Encoded.split("").reverse().join("");
    const dynamicSalt = Math.random().toString(36).substr(2, 8);
    const combined = `${reversed}${dynamicSalt}`;
    const hashed = btoa(combined);
    return hashed;
  };

  const openPerformanceTab = () => {
    setLoading(true);

    try {
      const encodedClientId = customEncodeClientId1(row.clientid);
      const clientUrl = `/performance/${encodedClientId}`;

      window.open(clientUrl, "_blank");
    } catch (error) {
      console.error("Error encoding client ID:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={openPerformanceTab}
      sx={{
        textTransform: "none",
        borderRadius: "8px",
        boxShadow: 3,
        "&:hover": {
          backgroundColor: "#1976d2",
          boxShadow: 5,
        },
      }}
      disabled={loading}
    >
      {loading ? <CircularProgress size={24} /> : "Performance"}
    </Button>
  );
};

export default PerformanceTab;
