import React, { useEffect, useState } from "react";
import axios from "axios";
import { Bar, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import "./quatchart.css";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const Quatchart = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://www.quatdashboard.com:8084/api/performance"
        );
        setData(response.data);
        const years = [...new Set(response.data.map((item) => item.year))];
        setYearOptions(years);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedYear) {
      setFilteredData(data.filter((item) => item.year === selectedYear));
    } else {
      setFilteredData(data);
    }
  }, [selectedYear, data]);

  const handleYearSelection = (year) => {
    setSelectedYear(year === selectedYear ? null : year); // Toggle selection for year
  };

  const formatMonth = (month) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return monthNames[month - 1]; // month is 1-indexed (1 = Jan, 12 = Dec)
  };

  // Calculate yearly sum of profits
  const yearlyProfits = yearOptions.map((year) => {
    const yearData = data.filter((item) => item.year === year);
    return {
      year,
      profitSum: yearData.reduce((sum, item) => sum + item.profit, 0),
    };
  });

  // Format data for Chart.js
  const barChartData = {
    labels: filteredData.map((item) => formatMonth(item.month)),
    datasets: [
      {
        label: "Profit (in Crores)",
        data: filteredData.map((item) => item.profit), // Convert profit to crores
        backgroundColor: filteredData.map((item) =>
          item.profit > 0 ? "rgba(39, 174, 96, 1)" : "rgba(231, 76, 60, 1)"
        ), // Green for positive profit, Red for negative
        borderWidth: 0, // Remove borders around bars
        // borderRadius: 5, // Adding border radius to bars
      },
    ],
  };

  // Pie chart data and options
  const pieChartData = {
    labels: yearlyProfits.map((item) => item.year),
    datasets: [
      {
        label: "Total Profit per Year",
        data: yearlyProfits.map((item) => item.profitSum),
        backgroundColor: yearlyProfits.map(
          (_, index) => `hsl(${index * 50}, 70%, 60%)`
        ), // Different color for each year
        borderWidth: 1,
      },
    ],
  };

  // Chart.js options
  const barChartOptions = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const item = filteredData[tooltipItem.dataIndex];
            return [
              `Capital: ${item.capital} Crores`,
              `Profit: ${item.profit} Crores`,
              `Profit Percent: ${item.profitPercent}%`,
            ];
          },
        },
      },
    },
    responsive: true,
    scales: {
      y: {
        title: {
          display: true,
          text: "Profit (in Crores)",
          font: {
            weight: "bold",
          },
        },
      },
    },
  };

  return (
    <div className="chart-container">
      {/* Year Buttons */}
      <div className="year-buttons">
        <button
          onClick={() => handleYearSelection(null)}
          className="year-button"
        >
          All Years
        </button>
        {yearOptions.map((year) => (
          <button
            key={year}
            onClick={() => handleYearSelection(year)}
            className={`year-button ${selectedYear === year ? "selected" : ""}`}
          >
            {year}
          </button>
        ))}
      </div>

      {/* Bar Chart */}
      <div className="chart-wrapper bar">
        <Bar data={barChartData} options={barChartOptions} />
      </div>

      {/* Pie Chart */}
      <div className="chart-wrapper pie">
        <Pie data={pieChartData} />
      </div>
    </div>
  );
};

export default Quatchart;
