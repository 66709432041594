import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [totp, setTotp] = useState(""); // New TOTP state
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    // Clear previous error messages
    setError("");

    if (username === "" || password === "" || totp === "") {
      setError("All fields are required!");
      return;
    }

    try {
      const response = await fetch(
        "https://www.quatdashboard.com:8084/api/login",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ username, password, totp: parseInt(totp) }), // Include TOTP in the request
        }
      );

      // Check if the response status indicates success
      if (response.status === 200) {
        const data = await response.json();
        localStorage.setItem("authToken", data.token); // Store the auth token (assuming the token is in response)
        navigate("/Dashboard");
      } else if (response.status === 401) {
        // Authentication failed (e.g., invalid credentials)
        setError("Invalid username, password, or TOTP. Please try again.");
      } else {
        // Other errors (e.g., server issues)
        setError("Something went wrong. Please try again later.");
      }
    } catch (err) {
      // Handle network errors
      console.error("Login error:", err);
      setError("Login failed. Please check your connection and try again.");
    }
  };

  return (
    <div className="login-container">
      {/* Company Name with Animation */}
      <h1 className="company-name">QUAT</h1>

      <form onSubmit={handleLogin} className="login-form">
        <h2>Login</h2>
        {error && <p className="error">{error}</p>}
        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="totp">TOTP</label>
          <input
            type="number" // Restrict input to numbers
            id="totp"
            maxLength="6"
            value={totp}
            onChange={(e) => setTotp(e.target.value)}
            required
          />
        </div>

        <button type="submit" className="login-btn">
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
