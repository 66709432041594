import React, { useEffect, useState } from "react";
import "./positionmodal.css"; // Add styling for your modal

function PositionModal({ clientID, onClose }) {
  const [positions, setPositions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPnl, setTotalPnl] = useState(0); // State to store total PnL

  useEffect(() => {
    if (clientID) {
      fetch(
        `https://www.quatdashboard.com:8084/api/checkPosition?clientID=${clientID}`
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch positions");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Parsed Response Data:", data); // Log parsed response data

          let extractedData = [];
          // Check if data is an array (Flattrade)
          if (Array.isArray(data)) {
            extractedData = data.map((position, index) => {
              const quantity = parseFloat(position.netqty);
              const lastPrice = parseFloat(position.lp);
              const realizedPnL = parseFloat(position.rpnl);
              const buyAvg = parseFloat(position.daybuyavgprc);
              const sellAvg = parseFloat(position.daysellavgprc);

              let pnl;
              if (quantity < 0) {
                const qty = Math.abs(quantity);
                pnl = (sellAvg - lastPrice) * qty + realizedPnL;
              } else {
                pnl = (lastPrice - buyAvg) * quantity + realizedPnL;
              }

              return {
                sno: index + 1,
                tradingsymbol: position.tsym,
                product: position.s_prdt_ali,
                quantity: position.netqty,
                average_price: position.netavgprc,
                last_price: position.lp,
                pnl: pnl.toFixed(2),
                status: position.netqty === "0" ? "Closed" : "Open",
              };
            });
          } else if (data.status === "success") {
            // Handle Zerodha response
            extractedData = data.data.net.map((position, index) => ({
              sno: index + 1,
              tradingsymbol: position.tradingsymbol,
              product: position.product,
              quantity: position.quantity,
              average_price: position.average_price,
              last_price: position.last_price,
              pnl: position.pnl,
              status: position.quantity === 0 ? "Closed" : "Open",
            }));
          } else if (data.positions && Array.isArray(data.positions.position)) {
            extractedData = data.positions.position.map((position, index) => {
              const quantity = parseFloat(position.current_quantity);
              const marketPrice = parseFloat(position.ltp);
              const value = parseFloat(position.ltp * position.quantity * 100);
              let pnl = 0;
              if (
                position.unrealizedpnl != null &&
                position.realizedpnl != null
              ) {
                pnl =
                  parseFloat(position.unrealizedpnl) +
                  parseFloat(position.realizedpnl);
              } else if (position.unrealizedpnl != null) {
                pnl = parseFloat(position.unrealizedpnl);
              } else if (position.realizedpnl != null) {
                pnl = parseFloat(position.realizedpnl);
              }
              return {
                sno: index + 1,
                tradingsymbol: position.symbol,
                product: "Equity/Option", // Adjust based on your requirement
                quantity: quantity.toFixed(2),
                average_price: (
                  position.avg_sellprice || position.avg_buyprice
                ).toFixed(2),
                last_price: marketPrice.toFixed(2),
                pnl: pnl.toFixed(2),
                status: quantity === 0 ? "Closed" : "Open",
              };
            });
          } else {
            console.error("Unexpected response format or error:", data);
            setPositions([]);
            setLoading(false);
            return;
          }

          // Calculate total PnL
          const totalPnlValue = extractedData.reduce(
            (sum, pos) => sum + parseFloat(pos.pnl || 0),
            0
          );

          setPositions(extractedData);
          setTotalPnl(totalPnlValue); // Set total PnL value
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching positions:", error);
          alert("Error fetching positions");
          setLoading(false);
        });
    }
  }, [clientID]);

  if (loading) {
    return <div className="modal">Loading positions...</div>;
  }

  return (
    <div className="modal">
      <div className="pac-modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h2>Positions for Client ID: {clientID}</h2>
        {positions.length > 0 ? (
          <>
            <table className="positions-table">
              <thead>
                <tr>
                  <th>Symbol</th>
                  <th>Product</th>
                  <th>Quantity</th>
                  <th>Avg. Price</th>
                  <th>Last Price</th>
                  <th>P&L</th>
                  <th>Status</th> {/* New Status column */}
                </tr>
              </thead>
              <tbody>
                {positions.map((position, index) => (
                  <tr key={index}>
                    <td>{position.tradingsymbol}</td>
                    <td>{position.product}</td>
                    <td>{position.quantity}</td>
                    <td>{position.average_price}</td>
                    <td>{position.last_price}</td>
                    <td>{position.pnl}</td>
                    <td>{position.status}</td> {/* Display status */}
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Display total PnL below the table */}
            <div className="total-pnl">
              <h3>Total P&L: {totalPnl.toFixed(2)}</h3>{" "}
              {/* Display the total PnL */}
            </div>
          </>
        ) : (
          <p>No positions available.</p>
        )}
      </div>
    </div>
  );
}

export default PositionModal;
