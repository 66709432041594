import React from "react";
import "./filterBar.css"; // Assuming your styling file is properly linked

function FilterBar({ onFilterChange }) {
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    onFilterChange(name, value); // Pass filter value back to the parent component
  };

  return (
    <div
      className="filter-bar"
      style={{ display: "flex", gap: "20px", marginBottom: "20px" }}
    >
      {/* Search input for filtering by name */}
      <input
        type="text"
        placeholder="Search by Name"
        name="search"
        onChange={handleFilterChange}
        style={{
          padding: "10px",
          fontSize: "16px",
          border: "1px solid #ccc",
          borderRadius: "5px",
          width: "250px",
        }}
      />

      <select name="priority" onChange={handleFilterChange}>
        <option value="all">Priority: All</option>
        <option value="asc">Ascending</option>
        <option value="desc">Descending</option>
      </select>

      <select name="broker" onChange={handleFilterChange}>
        <option value="all">Broker: All</option>
        <option value="zerodha">Zerodha</option>
        <option value="flattrade">Flattrade</option>
      </select>

      <select name="status" onChange={handleFilterChange}>
        <option value="all">Status: All</option>
        <option value="active">Active</option>
        <option value="inactive">Inactive</option>
      </select>

      <select name="server" onChange={handleFilterChange}>
        <option value="all">Server: All</option>
        <option value="1">Server 1</option>
        <option value="2">Server 2</option>
        <option value="3">Server 3</option>
      </select>
    </div>
  );
}

export default FilterBar;
