import React, { useState, useEffect } from "react";
import "./dashboard.css";
import Card from "./Card";
import Reports from "./Reports";
import Tokenupdate from "./Tokenupdate";
import Quatchart from "./Quatchart";

import LoadingScreen from "./LoadingScreen"; // Import the loading screen
// import Jobs from './Jobs';

const formatAmount = (amount) => {
  const isNegative = amount < 0;
  const absAmount = Math.abs(amount);

  let formattedAmount;

  if (absAmount >= 10000000) {
    // Check for crores first
    formattedAmount = `${(absAmount / 10000000).toFixed(1)}Cr`; // For crores
  } else if (absAmount >= 100000) {
    // Then check for millions
    formattedAmount = `${(absAmount / 100000).toFixed(1)}L`; // For millions
  } else if (absAmount >= 1000) {
    // Then check for thousands
    formattedAmount = `${(absAmount / 1000).toFixed(1)}K`; // For thousands
  } else {
    formattedAmount = absAmount; // For smaller amounts
  }

  return isNegative ? `-${formattedAmount}` : formattedAmount;
};

function Dashboard() {
  const [cards, setCards] = useState([]);
  const [sharingAmount, setSharingAmount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      setLoading(true);

      const totalRes = await fetch(
        "https://www.quatdashboard.com:8084/api/totalCapital"
      );
      if (!totalRes.ok) throw new Error("Error fetching total capital");
      const totalData = await totalRes.json();
      const formattedTotalData = {
        ...totalData,
        amount: formatAmount(totalData.amount),
        totalcapital: formatAmount(totalData.totalcapital),
        percentage: totalData.percentage, // Include percentage
      };

      const clientRes = await fetch(
        "https://www.quatdashboard.com:8084/api/Clients/count"
      );
      if (!clientRes.ok) throw new Error("Error fetching client count");
      const clientData = await clientRes.json();
      const formattedClientData = {
        ...clientData,
        amount: formatAmount(clientData.amount),
      };

      const sharingRes = await fetch(
        "https://www.quatdashboard.com:8084/api/totalSharingAmount"
      );
      if (!sharingRes.ok) throw new Error("Error fetching sharing amount");
      const sharingAmount = await sharingRes.json();
      const formattedSharingAmount = sharingAmount;

      setCards([formattedTotalData, formattedClientData]);
      setSharingAmount(formattedSharingAmount);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false); // Set loading to false when the data is fetched
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <LoadingScreen />; // Show the loading screen while fetching
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <section className="dashboard section">
      <div className="row">
        <div className="col-lg-8">
          <div className="row">
            {cards.length > 0 ? (
              cards.map((card) => <Card key={card.id} card={card} />)
            ) : (
              <div>No data available</div>
            )}
            {sharingAmount !== null &&
              sharingAmount !== undefined &&
              !isNaN(parseFloat(sharingAmount)) && (
                <Card
                  key="sharingAmount"
                  card={{
                    id: "sharingAmount",
                    name: "Total Sharing",
                    amount: formatAmount(
                      Math.max(parseFloat(sharingAmount), 0)
                    ), // Only formatAmount is called
                    icon: "bi bi-share-fill",
                  }}
                />
              )}

            {/* {(sharingAmount !== null && sharingAmount !== undefined && !isNaN(parseFloat(sharingAmount))) && (
                            <Card
                             key="sharingAmount"
                             card={{
                                 id: 'sharingAmount',
                                  name: 'Total Sharing',
                                   amount: formatAmount(Math.max(parseFloat(sharingAmount), 0)),  // Use formatted amount
                                   icon: 'bi bi-share-fill',
                                }}
                                 />
                         )} */}

            <div className="col-12">
              <Reports />
            </div>
            <div className="col-12">
              <Tokenupdate />
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="row">
            {/* <div className="col-12">
                            <Buttons />
                        </div> */}
            {/* <div className="col-12">
                            <News />
                        </div> */}
            <div className="col-12">
              <Quatchart />
            </div>
            {/* <div className="col-12">
                            <Jobs />
                        </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Dashboard;
