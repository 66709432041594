import React, { useState, useEffect } from "react";
import "./card.css";
import CardFilter from "./CardFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import axios from "axios"; // For making API requests

function Card({ card }) {
  const [filter, setFilter] = useState("Today");
  const [pnlData, setPnlData] = useState(null); // State to store P&L data
  const [customDates, setCustomDates] = useState({
    startDate: "",
    endDate: "",
  });

  const handleFilterChange = (filterData) => {
    if (typeof filterData === "object" && filterData.type === "custom") {
      setCustomDates({
        startDate: filterData.startDate,
        endDate: filterData.endDate,
      });
      setFilter("custom");
    } else {
      setFilter(filterData);
    }
  };

  // Function to format the amount in crores, lakhs, and thousands
  const formatAmount = (amount) => {
    let formattedAmount;
    const isNegative = amount < 0;
    const absAmount = Math.abs(amount);
    if (absAmount >= 10000000) {
      // Check for crores first
      formattedAmount = `${(absAmount / 10000000).toFixed(1)}Cr`; // For crores
    } else if (absAmount >= 100000) {
      // Then check for millions
      formattedAmount = `${(absAmount / 100000).toFixed(1)}L`; // For millions
    } else if (absAmount >= 1000) {
      // Then check for thousands
      formattedAmount = `${(absAmount / 1000).toFixed(1)}K`; // For thousands
    } else {
      formattedAmount = absAmount; // For smaller amounts
    }

    return isNegative ? `-${formattedAmount}` : formattedAmount;
  };

  useEffect(() => {
    if (
      filter === "custom" &&
      card.name === "P&L" &&
      customDates.startDate &&
      customDates.endDate
    ) {
      // Make API call to the backend using the custom date range from the UI
      axios
        .get(`https://www.quatdashboard.com:8084/api/pnl/filter`, {
          params: {
            filterType: "custom",
            startDate: customDates.startDate,
            endDate: customDates.endDate,
          },
        })
        .then((response) => {
          setPnlData(response.data); // Set P&L data from API response
        })
        .catch((error) => {
          console.error("Error fetching P&L data:", error);
        });
    }
  }, [filter, card.name, customDates]);

  // Determine the style for percentage change
  const percentageStyle = {
    color: card.percentage >= 0 ? "green" : "red",
    fontWeight: "bold",
    fontSize: "1rem", // Adjust font size as needed
    marginLeft: "0.5rem",
  };

  return (
    <div className="col-xxl-4 col-md-4">
      <div className="card info-card sales-card">
        <CardFilter filterChange={handleFilterChange} />
        <div className="card-body">
          <h5 className="card-title">
            {card.name}{" "}
            <span>| {filter === "custom" ? "Custom Filter" : filter}</span>
          </h5>

          <div className="d-flex align-items-center">
            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
              <i className={card.icon}></i>
            </div>
            <div className="ps-3 flex-grow-1">
              <div className="d-flex justify-content-between align-items-start">
                <div>
                  {/* Display data for P&L based on filter */}
                  <h6>
                    {filter === "custom" &&
                    card.name === "P&L" &&
                    pnlData !== null
                      ? formatAmount(pnlData) // Use the formatAmount function here
                      : card.name === "Revenue"
                      ? "$" + card.amount.toLocaleString("en-US")
                      : card.amount.toLocaleString("en-US")}
                  </h6>
                  {/* Only display capital info if available */}
                  {card.totalcapital && filter !== "custom" && (
                    <p className="capital-info">
                      {card.totalcapital.toLocaleString("en-US")}
                    </p>
                  )}
                </div>
                {/* Display percentage for P&L if available */}
                {card.name === "P&L" &&
                  card.percentage !== undefined &&
                  filter !== "custom" && (
                    <div style={percentageStyle}>
                      {card.percentage.toFixed(2)}%
                      <FontAwesomeIcon
                        icon={card.percentage >= 0 ? faArrowUp : faArrowDown}
                        style={{ marginLeft: "0.5rem" }}
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
