import React, { useState, useEffect } from "react";
import "./EditUserModal.css";

function EditUserModal({ userId, onClose }) {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editingData, setEditingData] = useState(null);

  useEffect(() => {
    if (userId) {
      fetch(`https://www.quatdashboard.com:8084/api/mainTable/${userId}`)
        .then((response) => response.json())
        .then((data) => {
          setUserData(data);
          setEditingData(data); // Initialize editingData
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          setLoading(false);
        });
    }
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditingData((prevData) => ({
      ...prevData,
      [name]: value, // This should update 'sharingpercentage' correctly
    }));
  };

  const handleSave = () => {
    // Implement save functionality (e.g., API call to update user data)
    fetch(
      `https://www.quatdashboard.com:8084/api/mainTable/updateuserprofile/${userId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editingData),
      }
    )
      .then((response) => {
        if (response.ok) {
          alert("Changes saved successfully!"); // Success alert
          onClose(); // Close modal on success
        } else {
          throw new Error("Failed to save changes");
        }
      })
      .catch((error) => {
        console.error("Error saving user data:", error);
      });
  };

  if (loading) {
    return (
      <div className="modal">
        <div className="loading">Loading user data...</div>
      </div>
    );
  }
  if (!userData) {
    return <div>No user data found.</div>;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h2>Edit User: {editingData.name}</h2>
        <label>
          Name:{" "}
          <input
            type="text"
            name="name"
            value={editingData.name}
            onChange={handleChange}
          />
        </label>
        <label>
          Client ID:{" "}
          <input
            type="text"
            name="clientid"
            value={editingData.clientid}
            onChange={handleChange}
          />
        </label>
        <label>
          Password:{" "}
          <input
            type="text"
            name="password"
            value={editingData.password}
            onChange={handleChange}
          />
        </label>
        <label>
          API Key:{" "}
          <input
            type="text"
            name="apikey"
            value={editingData.apikey}
            onChange={handleChange}
          />
        </label>
        <label>
          Secret Key:{" "}
          <input
            type="text"
            name="secretkey"
            value={editingData.secretkey}
            onChange={handleChange}
          />
        </label>
        <label>
          TOTPKey:{" "}
          <input
            type="text"
            name="totpkey"
            value={editingData.totpkey}
            onChange={handleChange}
          />
        </label>
        <label>
          Monthly PnL:{" "}
          <input
            type="text"
            name="monthlypnl"
            value={editingData.monthlypnl}
            onChange={handleChange}
          />
        </label>
        <label>
          Current PnL:{" "}
          <input
            type="text"
            name="currentmonth"
            value={editingData.currentmonth}
            onChange={handleChange}
          />
        </label>
        <label>
          Chat ID:{" "}
          <input
            type="text"
            name="chatid"
            value={editingData.chatid}
            onChange={handleChange}
          />
        </label>
        {/* <label>Is Hedge: 
          <select name="ishedge" value={editingData.ishedge} onChange={handleChange}>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </select>
        </label> */}
        {/* <label>Profit Sharing: <input type="text" name="profitsharing" value={editingData.profitsharing} onChange={handleChange} /></label> */}
        <label>
          Sharing %:{" "}
          <input
            type="text"
            name="sharingpercentage"
            value={editingData.sharingpercentage || "0"}
            onChange={(e) => handleChange(e)}
          />
        </label>
        <label>
          Previous Month Loss:{" "}
          <input
            type="text"
            name="prevmonthloss"
            value={editingData.prevmonthloss}
            onChange={handleChange}
          />
        </label>
        {/* <label>Option Compounding: 
          <select name="optioncompounding" value={editingData.optioncompounding} onChange={handleChange}>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </select>s
        </label> */}
        {/* <label>Broker: <input type="text" name="broker" value={editingData.broker} onChange={handleChange} /></label> */}
        <label>
          Broker:
          <select
            name="broker"
            value={editingData.broker}
            onChange={handleChange}
          >
            <option value="">Select Broker</option> {/* Default empty option */}
            <option value="zerodha">Zerodha</option>
            <option value="flattrade">Flattrade</option>
          </select>
        </label>

        {/* <label>Server: <input type="text" name="server" value={editingData.server} onChange={handleChange} /></label> */}
        <label>
          Server:
          <select
            name="server"
            value={editingData.server}
            onChange={handleChange}
          >
            <option value="">Select Server</option> {/* Default empty option */}
            <option value="1">Server 1</option>
            <option value="2">Server 2</option>
          </select>
        </label>

        {/* <label>Status: 
          <select name="status" value={editingData.status} onChange={handleChange}>
            <option value={true}>Active</option>
            <option value={false}>Inactive</option>
          </select>
        </label> */}
        <label>
          Priority:{" "}
          <input
            type="text"
            name="priority"
            value={editingData.priority}
            onChange={handleChange}
          />
        </label>
        {/* Add more fields as needed */}
        <button onClick={handleSave}>Save Changes</button>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
}

export default EditUserModal;
