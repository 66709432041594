import React from 'react';
import './ClientDetailsPopup.css';
const ClientDetailsPopup = ({ clientDetails, onClose, onCopy }) => {
    return (
      <div className="popup">
        <div className="popup-content">
          <h3>Client Details</h3>
          <span className="close" onClick={onClose}>&times;</span>
          <p>
            <strong>Client ID:</strong>
            <span>{clientDetails.clientid}</span>
            <span onClick={() => onCopy('clientid')} className="copy-button">
              <i className="bi bi-copy"></i>
            </span>
          </p>
          <p>
            <strong>Password:</strong>
            <span>{clientDetails.password}</span>
            <span onClick={() => onCopy('password')} className="copy-button">
              <i className="bi bi-copy"></i>
            </span>
          </p>
          <p>
            <strong>Login URL:</strong>
            <a href={clientDetails.loginUrl} target="_blank" rel="noopener noreferrer">
              {clientDetails.loginUrl}
            </a>
          </p>
        </div>
      </div>
    );
  };  

export default ClientDetailsPopup;
