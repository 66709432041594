import React from "react";
import "./zeropackagemodal.css"; // Create a CSS file for styling if needed
import axios from "axios"; // Install axios using 'npm install axios'

function Zeroindex({ isVisible, onClose, onPackageAction }) {
  if (!isVisible) return null;

  // Function to handle API calls based on the action (backup, retrieve, zero)
  const handlePackageAction = (action) => {
    let url = "";

    switch (action) {
      case "nifty":
        url = "https://www.quatdashboard.com:8084/api/mainTable/reset/nifty";
        break;
      case "banknifty":
        url =
          "https://www.quatdashboard.com:8084/api/mainTable/reset/banknifty";
        break;
      case "finnifty":
        url = "https://www.quatdashboard.com:8084/api/mainTable/reset/finnifty";
        break;
      case "midcap":
        url = "https://www.quatdashboard.com:8084/api/mainTable/reset/midcap";
        break;
      case "sensex":
        url = "https://www.quatdashboard.com:8084/api/mainTable/reset/sensex";
        break;
      case "bankex":
        url = "https://www.quatdashboard.com:8084/api/mainTable/reset/bankex";
        break;

      default:
        return;
    }

    // Call the respective API based on action
    axios
      .post(url)
      .then((response) => {
        console.log(response.data); // Log the response or handle success
        onPackageAction(action); // You can call the passed callback if needed
        alert(
          `${
            action.charAt(0).toUpperCase() + action.slice(1)
          } action completed successfully!`
        );
      })
      .catch((error) => {
        console.error("There was an error!", error); // Handle the error
        alert(`Failed to perform ${action} action.`);
      });
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h2>Package Actions</h2>
        <div className="btn-container">
          <button
            className="btn-package"
            onClick={() => handlePackageAction("nifty")}
          >
            Nifty
          </button>
          <button
            className="btn-package"
            onClick={() => handlePackageAction("banknifty")}
          >
            Banknifty
          </button>
          <button
            className="btn-package"
            onClick={() => handlePackageAction("finnifty")}
          >
            FinNifty
          </button>
          <button
            className="btn-package"
            onClick={() => handlePackageAction("midcap")}
          >
            Midcap
          </button>
          <button
            className="btn-package"
            onClick={() => handlePackageAction("sensex")}
          >
            Sensex
          </button>
          <button
            className="btn-package"
            onClick={() => handlePackageAction("bankex")}
          >
            BankEx
          </button>
        </div>
        {/* Add the close button with a label */}
        {/* <button className="btn-close" onClick={onClose}></button> */}
      </div>
    </div>
  );
}

export default Zeroindex;
