import React from 'react';
import PageTitle from '../Dashboard/PageTitle';
import UserPage from './UserPage';
import '../Dashboard/main.css';

function Usermain() {
  return (
    <main id="main" className="main">
    <PageTitle page={"User"}/>
    <UserPage/>
</main>
  )
}

export default Usermain
