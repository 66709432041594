import React from 'react';
import { useNavigate } from 'react-router-dom';
import profileImg from '../../Assets/logo/profile.png';

function NavAvatar() {
  const navigate = useNavigate(); // Hook to navigate between pages

  const handleSignOut = () => {
    // Ask for confirmation before logging out
    const confirmed = window.confirm("Are you sure you want to sign out?");
    if (confirmed) {
      // Clear any session storage, tokens, or user info here (if any)
      localStorage.removeItem('authToken'); // Example of token removal

      // Redirect to the login page
      navigate('/login');
    }
  };

  return (
    <li className="nav-item dropdown pe-3">
      <button
        className="nav-link nav-profile d-flex align-items-center pe-0 btn"
        data-bs-toggle="dropdown"
        type="button"
      >
        <img src={profileImg} alt="Profile" className="rounded-circle" />
        <span className="d-none d-md-block dropdown-toggle ps-2">Admin</span>
      </button>

      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
        <li className="dropdown-header">
          <h6>Admin</h6>
          <span>Web Developer</span>
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>

        <li>
          <a className="dropdown-item d-flex align-items-center" href="users-profile.html">
            <i className="bi bi-person"></i>
            <span>My Profile</span>
          </a>
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>

        <li>
          <a className="dropdown-item d-flex align-items-center" href="users-profile.html">
            <i className="bi bi-gear"></i>
            <span>Account Settings</span>
          </a>
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>

        <li>
          <a className="dropdown-item d-flex align-items-center" href="pages-faq.html">
            <i className="bi bi-question-circle"></i>
            <span>Need Help?</span>
          </a>
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>

        <li>
          <button
            className="dropdown-item d-flex align-items-center btn"
            onClick={handleSignOut} // Call the sign-out function when clicked
          >
            <i className="bi bi-arrow-right"></i>
            <span>Sign Out</span>
          </button>
        </li>
      </ul>
    </li>
  );
}

export default NavAvatar;
