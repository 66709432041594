import React, { useState, useEffect, useCallback } from "react";
import "./tokenupdate.css";
import StatusFilter from "./StatusFilter"; // Updated filter component
import TokenUpdateTable from "./TokenUpdateTable";

function Tokenupdate() {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filter, setFilter] = useState("all"); // Default filter

  const handleFilterChange = (status) => {
    setFilter(status);
  };

  // Wrap fetchData in useCallback
  const fetchData = useCallback(async () => {
    try {
      const response = await fetch(
        "https://www.quatdashboard.com:8084/api/tokenstatus"
      );
      const data = await response.json();
      setItems(data);
      // Initial filtering
      applyFilter(filter, data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [filter]); // Add 'filter' as a dependency

  const applyFilter = (filter, data) => {
    if (filter === "all") {
      setFilteredItems(data);
    } else {
      // Replace 'status' with the correct field name if necessary
      setFilteredItems(data.filter((item) => item.status === filter));
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]); // Now fetchData is added as a dependency

  useEffect(() => {
    applyFilter(filter, items);
  }, [filter, items]);

  return (
    <div className="card recent-sales overflow-auto">
      <StatusFilter filterChange={handleFilterChange} />{" "}
      {/* Use StatusFilter */}
      <div className="card-body">
        <h5 className="card-title">
          Token Update <span>| {filter}</span>
        </h5>
        <TokenUpdateTable items={filteredItems} />
      </div>
    </div>
  );
}

export default Tokenupdate;
