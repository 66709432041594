import React, { useState } from "react";
import "./tokenupdate.css"; // Import the CSS file

function TokenUpdateTable({ items, setItems }) {
  const [loadingClientID, setLoadingClientID] = useState(null); // Track the loading state by clientID
  const [completedClientIDs, setCompletedClientIDs] = useState(new Set()); // Track completed clientIDs

  // Utility function to check if the date is today
  function isToday(dateString) {
    const date = new Date(dateString);
    const today = new Date();
    return date.toLocaleDateString() === today.toLocaleDateString();
  }

  const handleUpdate = (clientID) => {
    setLoadingClientID(clientID); // Set the loading state for the specific client

    fetch(`https://www.quatdashboard.com:8084/api/Clients/${clientID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        handleAutoLogin(data); // Automatically call handleAutoLogin with the client details
      })
      .catch((error) => {
        console.error("Error fetching client details:", error);
        setLoadingClientID(null); // Reset loading state if there's an error
      });
  };

  const handleAutoLogin = (clientDetails) => {
    if (!clientDetails) {
      console.error("No client details available for auto login");
      setLoadingClientID(null);
      return;
    }

    fetch("https://www.quatdashboard.com:8084/api/autoLogin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        apiKey: clientDetails.apiKey,
        clientID: clientDetails.clientID,
        password: clientDetails.password,
        totpKey: clientDetails.totpKey,
        chatID: clientDetails.chatID,
        broker: clientDetails.broker,
      }),
    })
      .then((response) => response.text())
      .then((message) => {
        console.log(message);
        alert("Auto login process initiated");
        setCompletedClientIDs((prev) =>
          new Set(prev).add(clientDetails.clientID)
        ); // Mark as completed
      })
      .catch((error) => {
        console.error("Error during auto login:", error);
      })
      .finally(() => {
        setLoadingClientID(null); // Reset loading state after processing
      });
  };

  return (
    <div className="table-container">
      <table className="table table-borderless datatable">
        <thead className="table-light">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Client ID</th>
            <th scope="col">Client</th>
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {items && items.length > 0 ? (
            items.map((item) => (
              <tr key={item._id}>
                <th scope="row">{item._id}</th>
                <td>{item.clientID}</td>
                <td>{item.client}</td>
                <td>
                  <span
                    className={`badge bg-${
                      isToday(item.dateModified) ? "success" : "danger"
                    }`}
                  >
                    {isToday(item.dateModified) ? "Success" : "Failed"}
                  </span>
                </td>
                <td>
                  <button
                    className={`update-button ${
                      completedClientIDs.has(item.clientID) ? "completed" : ""
                    }`}
                    onClick={() =>
                      !completedClientIDs.has(item.clientID) &&
                      handleUpdate(item.clientID)
                    }
                    disabled={
                      loadingClientID === item.clientID ||
                      isToday(item.dateModified)
                    } // Disable if it's today's date
                  >
                    {loadingClientID === item.clientID
                      ? "Processing..."
                      : isToday(item.dateModified)
                      ? "Completed"
                      : completedClientIDs.has(item.clientID)
                      ? "Completed"
                      : "Update"}
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">No data available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default TokenUpdateTable;
