// Table.js
import React from 'react';
import { Box} from '@mui/material';
import PerformanceTab from './PerformanceTab1'; // Import the new component

const Table = ({ rows, searchText }) => {
  const filteredRows = rows.filter((row) =>
    row.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <Box sx={{ flexGrow: 1, width: '100%' }}>
      <Box sx={{ width: '100%', backgroundColor: '#ffffff', borderRadius: 2, boxShadow: 2, overflow: 'hidden' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ padding: '10px', textAlign: 'center', backgroundColor: '#1976d2', color: 'white' }}>ID</th>
              <th style={{ padding: '10px', textAlign: 'center', backgroundColor: '#1976d2', color: 'white' }}>Name</th>
              <th style={{ padding: '10px', textAlign: 'center', backgroundColor: '#1976d2', color: 'white' }}>Capital</th>
              <th style={{ padding: '10px', textAlign: 'center', backgroundColor: '#1976d2', color: 'white' }}>Monthly P&L</th>
              <th style={{ padding: '10px', textAlign: 'center', backgroundColor: '#1976d2', color: 'white' }}>Previous Loss</th>
              <th style={{ padding: '10px', textAlign: 'center', backgroundColor: '#1976d2', color: 'white' }}>Current Drawdown</th>
              <th style={{ padding: '10px', textAlign: 'center', backgroundColor: '#1976d2', color: 'white' }}>Max Drawdown</th>
              <th style={{ padding: '10px', textAlign: 'center', backgroundColor: '#1976d2', color: 'white' }}>ROI</th>
              <th style={{ padding: '10px', textAlign: 'center', backgroundColor: '#1976d2', color: 'white' }}>Performance</th>
            </tr>
          </thead>
          <tbody>
            {filteredRows.map((row) => (
              <tr key={row.id}>
                <td style={{ padding: '10px', textAlign: 'center' }}>{row.id}</td>
                <td style={{ padding: '10px', textAlign: 'center' }}>{row.fullname}</td>
                <td style={{ padding: '10px', textAlign: 'center' }}>₹{row.capital}</td>
                <td style={{ padding: '10px', textAlign: 'center' }}>₹{row.monthlypnl}</td>
                <td style={{ padding: '10px', textAlign: 'center' }}>₹{row.prevmonthloss}</td>
                <td style={{ padding: '10px', textAlign: 'center' }}>{row.currentDrawdown}%</td>
                <td style={{ padding: '10px', textAlign: 'center' }}>{row.maxDrawdown}%</td>
                <td style={{ padding: '10px', textAlign: 'center' }}>{row.cummulativeRoi}%</td>
                <td style={{ padding: '10px', textAlign: 'center' }}>
                  <PerformanceTab row={row} /> {/* Use the new component */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </Box>
  );
};

export default Table;
