import React, { useEffect, useState } from "react";
import axios from "axios";
import "./SummaryCards.css"; // Import the updated CSS for styling

const SummaryCards = () => {
  const [summaryData, setSummaryData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSummaryData = async () => {
      try {
        const response = await axios.get(
          "https://www.quatdashboard.com:8084/api/invoices/summary"
        );
        setSummaryData(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch summary data. Please try again.");
        setLoading(false);
      }
    };

    fetchSummaryData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="summary-cards-container">
      <div className="invoice-card">
        <h3>Total Amount Received</h3>
        <p className="amount">
          ₹
          {summaryData.totalAmount.toLocaleString("en-IN", {
            minimumFractionDigits: 2,
          })}
        </p>
      </div>
      <div className="invoice-card">
        <h3>Amount Yet to Receive This Month</h3>
        <p className="amount">
          ₹
          {summaryData.amountYetToReceive.toLocaleString("en-IN", {
            minimumFractionDigits: 2,
          })}
        </p>
      </div>
      <div className="invoice-card">
        <h3>Amount Received This Month</h3>
        <p className="amount">
          ₹
          {summaryData.amountReceivedThisMonth.toLocaleString("en-IN", {
            minimumFractionDigits: 2,
          })}
        </p>
      </div>
      <div className="invoice-card">
        <h3>Payed GST</h3>
        <p className="amount">
          ₹
          {summaryData.totalGST.toLocaleString("en-IN", {
            minimumFractionDigits: 2,
          })}
        </p>
      </div>
    </div>
  );
};

export default SummaryCards;
