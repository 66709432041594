import React from 'react';
import { TextField, Box } from '@mui/material';

const SearchBar = ({ searchText, setSearchText }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
      <TextField
        label="Search by Name"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        variant="standard"
        sx={{
          width: '100%',
          maxWidth: '200px',
          height: '50px',
          '& .MuiInputLabel-root': {
            color: '#1976d2',
            fontWeight: '600',
            fontSize: '0.875rem',
            top: '-10px',
            left: '14px',
          },
          '& .MuiInputBase-input': {
            padding: '8px',
            fontSize: '14px',
            color: '#333',
          },
          transition: 'all 0.3s ease',
        }}
      />
    </Box>
  );
};

export default SearchBar;
