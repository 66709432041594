import "bootstrap-icons/font/bootstrap-icons.css";
import "remixicon/fonts/remixicon.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import { useNavigate } from "react-router-dom";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import "./App.css";
import Header from "./components/Header/Header";
import SideBar from "./components/Dashboard/SideBar";
import Main from "./components/Dashboard/Main";
import UserMain from "./components/Users/Usermain";
import Login from "./components/Login/Login";
import Reportmain from "./components/Reports/Reportmain";
import ProtectedRoute from "./components/Dashboard/ProtectedRoute";
import PerformancePage from "./components/Reports/PerformancePage";
import Paymentmain from "./components/Payment/paymentmain";

import useIdleTimer from "./useIdleTimer";

function App() {
  const location = useLocation();
  const navigate = useNavigate(); // To programmatically navigate
  const isPerformancePage = location.pathname.startsWith("/performance/");

  const handleIdle = () => {
    console.log("User is inactive. Logging out...");
    navigate("/login"); // Redirect to login page
  };

  // Set idle timeout to 3 hours (3 * 60 * 60 * 1000 milliseconds)
  useIdleTimer(3 * 60 * 60 * 1000, handleIdle);

  return (
    <>
      {/* Conditionally render Header and SideBar if not on login or performance page */}
      {location.pathname !== "/login" && !isPerformancePage && <Header />}
      {location.pathname !== "/login" && !isPerformancePage && <SideBar />}

      <main id="Route" className="Route">
        <Routes>
          {/* Redirect the base URL (/) to /login */}
          <Route path="/" element={<Navigate to="/login" />} />

          {/* Public route */}
          <Route path="/login" element={<Login />} />

          {/* Protected routes */}
          <Route
            path="/Dashboard"
            element={
              <ProtectedRoute>
                <Main />
              </ProtectedRoute>
            }
          />
          <Route
            path="/User"
            element={
              <ProtectedRoute>
                <UserMain />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Report"
            element={
              <ProtectedRoute>
                <Reportmain />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Payment"
            element={
              <ProtectedRoute>
                <Paymentmain />
              </ProtectedRoute>
            }
          />
          <Route
            path="/performance/:encodedClientId"
            element={<PerformancePage />}
          />
        </Routes>
      </main>
    </>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
//https://www.quatdashboard.com:8084
//https://www.quatdashboard.com:8084
// https://www.quatdashboard.com:8084
