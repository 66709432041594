import React from 'react';
import './logo.css';
import logo from '../../Assets/logo/QuatLogo (1).png';

function Logo() {
  const handleToggleSidebar = () => {
    document.body.classList.toggle('toggle-sidebar');
  };

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <a href="/Dashboard" className="logo d-flex align-items-center">
          <img src={logo} alt="Logo" className="logo-img"/>
          <span className="d-none d-lg-block ms-2">AdminDashboard</span>
        </a>
      </div>
      <i
        className="bi bi-list toggle-sidebar-btn"
        onClick={handleToggleSidebar}
      ></i>
    </div>
  );
}

export default Logo;
