import React, { useState, useEffect } from "react";
import "./usertable.css";
import FilterBar from "./FilterBar";
import EditUserModal from "./EditUserModal"; // Import the new component
import PositionModal from "./PositionModal";
import OrderModal from "./OrderModal";
import ClientDetailsPopup from "./ClientDetailsPopup"; // Import the new component

function UserTable() {
  const [filters, setFilters] = useState({
    priority: "all",
    broker: "all",
    server: "all",
    status: "all",
    server: "all",
  });

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserName, setSelectedUserName] = useState("");
  const [packages, setPackages] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editingUserId, setEditingUserId] = useState(null); // State to track the user being edited
  const [showPositionModal, setShowPositionModal] = useState(false); // For showing positions
  const [selectedClientID, setSelectedClientID] = useState(null);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [selectedClientIDForOrder, setSelectedClientIDForOrder] =
    useState(null);

  const [clientDetails, setClientDetails] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const handleReset = () => {
    const confirmed = window.confirm(
      "Are you sure you want to reset all packages to zero?"
    );
    if (confirmed) {
      resetPackagesToZero();
    }
  };

  useEffect(() => {
    fetch("https://www.quatdashboard.com:8084/api/mainTable/all")
      .then((response) => response.json())
      .then((data) => {
        setUsers(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  const handleFilterChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleEditClick = (userId) => {
    setEditingUserId(userId); // Set the user ID for editing
    setIsEditing(true); // Open the editing mode for packages
  };

  const openModal = (user) => {
    setSelectedUser(user.id);
    setSelectedUserName(user.name);
    setPackages(user);
    setShowModal(true);
    setIsEditing(false); // Reset edit mode when opening modal for packages
    setEditingUserId(null); // Reset editing user ID for the packages modal
  };
  const closeModal = () => {
    setShowModal(false);
    setSelectedUser(null);
    setSelectedUserName("");
    setPackages(null);
    setIsEditing(false); // Reset edit mode when closing modal
  };

  const handlePositionClick = (clientID) => {
    setSelectedClientID(clientID);
    setShowPositionModal(true); // Show the position modal
  };

  const closePositionModal = () => {
    setShowPositionModal(false);
    setSelectedClientID(null); // Clear client ID when closing modal
  };

  const handleOrderClick = (clientID) => {
    setSelectedClientIDForOrder(clientID);
    setShowOrderModal(true);
  };

  const closeOrderModal = () => {
    setShowOrderModal(false);
    setSelectedClientIDForOrder(null);
  };

  const handleDeactivateUser = (userId) => {
    if (window.confirm("Are you sure you want to deactivate this user?")) {
      fetch(`https://www.quatdashboard.com:8084/api/mainTable/${userId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status: false }),
      })
        .then((response) => response.json())
        .then(() => {
          setUsers(
            users.map((user) =>
              user.id === userId ? { ...user, status: false } : user
            )
          );
          alert("User deactivated successfully.");
        })
        .catch((error) => {
          console.error("Error updating user status:", error);
        });
    }
  };

  const handleActivateUser = (userId) => {
    if (window.confirm("Are you sure you want to activate this user?")) {
      fetch(`https://www.quatdashboard.com:8084/api/mainTable/${userId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status: true }),
      })
        .then((response) => response.json())
        .then(() => {
          setUsers(
            users.map((user) =>
              user.id === userId ? { ...user, status: true } : user
            )
          );
          alert("User activated successfully.");
        })
        .catch((error) => {
          console.error("Error updating user status:", error);
        });
    }
  };

  // Reset package values to zero and update the backend
  const resetPackagesToZero = () => {
    const updatedPackages = { ...packages };
    for (let i = 0; i < 9; i++) {
      updatedPackages[`nc${i}`] = 0;
      updatedPackages[`bnc${i}`] = 0;
      updatedPackages[`fnc${i}`] = 0;
      updatedPackages[`mnc${i}`] = 0;
      updatedPackages[`sc${i}`] = 0;
      updatedPackages[`xc${i}`] = 0;
    }

    // Call the backend API to update the package counts
    fetch(
      `https://www.quatdashboard.com:8084/api/mainTable/reset/${selectedUser}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedPackages),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to update packages in the backend");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Packages updated successfully in the backend", data);
        alert("Packages reset to zero and updated successfully");
        setPackages(updatedPackages); // Update local state
      })
      .catch((error) => {
        console.error("Error updating packages:", error);
        alert("Error updating packages in the backend");
      });
  };

  // Save edited packages to the backend
  const saveChanges = () => {
    fetch(
      `https://www.quatdashboard.com:8084/api/mainTable/update/${selectedUser}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(packages),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to save changes in the backend");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Changes saved successfully in the backend", data);
        alert("Changes saved successfully");
        setIsEditing(false); // Exit edit mode
      })
      .catch((error) => {
        console.error("Error saving changes:", error);
        alert("Error saving changes in the backend");
      });
  };

  let filteredUsers = users.filter((user) => {
    // Apply all filters in one go to optimize performance
    const userName = user.name ? user.name.toLowerCase() : "";
    const searchTerm = filters.search ? filters.search.toLowerCase() : "";
    const filterByServer =
      filters.server === "all" || user.server === parseInt(filters.server, 10);
    const filterByBroker =
      filters.broker === "all" || user.broker === filters.broker;
    const filterByStatus =
      filters.status === "all" ||
      (filters.status === "active" && user.status) ||
      (filters.status === "inactive" && !user.status);

    return (
      userName.includes(searchTerm) &&
      filterByServer &&
      filterByBroker &&
      filterByStatus
    );
  });

  // Sorting logic
  if (filters.priority === "asc") {
    filteredUsers.sort((a, b) => a.priority - b.priority);
  } else if (filters.priority === "desc") {
    filteredUsers.sort((a, b) => b.priority - a.priority);
  }

  const handleCheckClick = (clientID) => {
    fetch(
      `https://www.quatdashboard.com:8084/api/mainTable/${clientID}/details`
    )
      .then((response) => response.json())
      .then((data) => {
        setClientDetails(data);
        setShowPopup(true); // Show the popup with client details
      })
      .catch((error) => {
        console.error("Error fetching client details:", error);
      });
  };

  const closePopup = () => {
    setShowPopup(false);
    setClientDetails(null);
  };

  const handleCopy = (textToCopy) => {
    const text =
      textToCopy === "clientid"
        ? clientDetails.clientid
        : clientDetails.password;

    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          alert("Client details copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } else {
      // Fallback for older browsers
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand("copy");
        alert("Client details copied to clipboard!");
      } catch (err) {
        console.error("Failed to copy: ", err);
      } finally {
        document.body.removeChild(textarea);
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <FilterBar onFilterChange={handleFilterChange} />

      <table className="user-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Client</th>
            <th>ClientID</th>
            <th>Priority</th>
            <th>Packages</th>
            <th>Broker</th>
            <th>Positions</th>
            <th>Order</th>
            <th>Status</th>
            <th>Actions</th>
            <th>Server</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.name.charAt(0).toUpperCase() + user.name.slice(1)}</td>
              <td>{user.clientid.toUpperCase()}</td>
              <td>{user.priority || "N/A"}</td>
              <td>
                <button className="btn-action" onClick={() => openModal(user)}>
                  View
                </button>
              </td>
              <td>{user.broker.toUpperCase()}</td>
              {/* <td>{user.server}</td> */}
              <td>
                <button
                  className="btn-action"
                  onClick={() => handlePositionClick(user.clientid)}
                >
                  Position
                </button>
              </td>
              <td>
                <button
                  className="btn-action"
                  onClick={() => handleOrderClick(user.clientid)}
                >
                  Order
                </button>
              </td>
              <td>
                <button
                  className={`btn-status ${
                    user.status ? "btn-active" : "btn-inactive"
                  }`}
                  onClick={() => {
                    if (user.status) {
                      handleDeactivateUser(user.id);
                    } else {
                      handleActivateUser(user.id);
                    }
                  }}
                >
                  {user.status ? "Active" : "Inactive"}
                </button>
              </td>
              <td>
                <div>
                  <button
                    className="btn-action"
                    onClick={() => handleEditClick(user.id)} // Call the edit handler
                  >
                    Edit
                  </button>
                  <button
                    className="btn-action"
                    onClick={() => handleCheckClick(user.clientid)}
                  >
                    Check
                  </button>
                </div>
              </td>
              <td>{user.server}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {showPositionModal && (
        <PositionModal
          clientID={selectedClientID}
          onClose={closePositionModal}
        />
      )}
      {showOrderModal && (
        <OrderModal
          clientID={selectedClientIDForOrder}
          onClose={closeOrderModal}
        />
      )}
      {showPopup && clientDetails && (
        <ClientDetailsPopup
          clientDetails={clientDetails}
          onClose={closePopup}
          onCopy={handleCopy}
        />
      )}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <h2>Packages for {selectedUserName}</h2>

            {packages ? (
              <table className="packages-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Nifty</th>
                    <th>BankNifty</th>
                    <th>FinNifty</th>
                    <th>MidCap</th>
                    <th>Sensex</th>
                    <th>BankEx</th>
                  </tr>
                </thead>
                <tbody>
                  {[...Array(9)].map((_, index) => (
                    <tr key={index}>
                      <td>{`c${index}`}</td>
                      {isEditing ? (
                        <>
                          <td>
                            <input
                              type="number"
                              value={packages[`nc${index}`] || 0}
                              onChange={(e) => {
                                setPackages({
                                  ...packages,
                                  [`nc${index}`]: Number(e.target.value),
                                });
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              value={packages[`bnc${index}`] || 0}
                              onChange={(e) => {
                                setPackages({
                                  ...packages,
                                  [`bnc${index}`]: Number(e.target.value),
                                });
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              value={packages[`fnc${index}`] || 0}
                              onChange={(e) => {
                                setPackages({
                                  ...packages,
                                  [`fnc${index}`]: Number(e.target.value),
                                });
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              value={packages[`mnc${index}`] || 0}
                              onChange={(e) => {
                                setPackages({
                                  ...packages,
                                  [`mnc${index}`]: Number(e.target.value),
                                });
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              value={packages[`sc${index}`] || 0}
                              onChange={(e) => {
                                setPackages({
                                  ...packages,
                                  [`sc${index}`]: Number(e.target.value),
                                });
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              value={packages[`xc${index}`] || 0}
                              onChange={(e) => {
                                setPackages({
                                  ...packages,
                                  [`xc${index}`]: Number(e.target.value),
                                });
                              }}
                            />
                          </td>
                        </>
                      ) : (
                        <>
                          <td>{packages[`nc${index}`]}</td>
                          <td>{packages[`bnc${index}`]}</td>
                          <td>{packages[`fnc${index}`]}</td>
                          <td>{packages[`mnc${index}`]}</td>
                          <td>{packages[`sc${index}`]}</td>
                          <td>{packages[`xc${index}`]}</td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div>Loading packages...</div>
            )}

            <div className="modal-actions">
              {/* <button onClick={resetPackagesToZero}>Reset to Zero</button> */}
              <button onClick={handleReset}>Reset to Zero</button>
              {isEditing ? (
                <button onClick={saveChanges}>Save Changes</button>
              ) : (
                <button onClick={() => setIsEditing(true)}>
                  Edit Packages
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      {showPositionModal && (
        <PositionModal
          clientID={selectedClientID}
          onClose={closePositionModal}
        />
      )}
      {editingUserId && (
        <EditUserModal
          userId={editingUserId}
          onClose={() => {
            setEditingUserId(null); // Close modal by resetting editingUserId
            setIsEditing(false); // Reset editing state
          }}
        />
      )}
    </div>
  );
}

export default UserTable;
