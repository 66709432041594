import React from 'react';
import PageTitle from '../Dashboard/PageTitle';
import '../Dashboard/main.css';
// import ReportContainer from './ReportContainerWithTable';
import ReportContainer from './App';

function Reportmain() {
  return (
    <main id="main" className="main">
    <PageTitle page={"Report"}/>
    <ReportContainer/>
</main>
  )
}

export default Reportmain
