import React from 'react';
import './pnlmodal.css'; // Add custom CSS styles

const PnlModal = ({ isVisible, onClose, pnlData }) => {
  if (!isVisible) return null;

  // Calculate the total P&L
  const totalPnl = pnlData.reduce((sum, client) => sum + parseFloat(client.pnl), 0).toFixed(2);

  // Split the pnlData into two columns
  const half = Math.ceil(pnlData.length / 2);
  const firstColumnData = pnlData.slice(0, half);
  const secondColumnData = pnlData.slice(half);

  return (
    <div className="pnl-modal">
      <div className="pnl-modal-content">
        <span className="close" onClick={onClose} title="Close Modal">
          &times; {/* This represents the "X" close button */}
        </span>
        <h2>Client P&L Data</h2>
        
        {/* Display the total P&L above the columns */}
        <div className="total-pnl">
          <strong>Total P&L:</strong> {totalPnl}
        </div>

        <div className="pnl-container">
          <div className="pnl-column">
            <table className="pnl-table">
              <thead>
                <tr>
                  <th>UserID</th>
                  <th>Name</th>
                  <th>P&L</th>
                </tr>
              </thead>
              <tbody>
                {firstColumnData.map((client, index) => (
                  <tr key={index}>
                    <td>{client.clientId}</td>
                    <td>{client.clientName}</td>
                    <td>{client.pnl.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pnl-column">
            <table className="pnl-table">
              <thead>
                <tr>
                  <th>UserID</th>
                  <th>Name</th>
                  <th>P&L</th>
                </tr>
              </thead>
              <tbody>
                {secondColumnData.map((client, index) => (
                  <tr key={index}>
                    <td>{client.clientId}</td>
                    <td>{client.clientName}</td>
                    <td>{client.pnl.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PnlModal;
