import React from 'react'
import './nav.css';
// import NavNotice from './NavNotice';
import NavAvatar from './NavAvatar';
// import NavMessage from './NavMessage';

function Nav() {
  return (
<nav className="header-nav ms-auto">
    <ul className="d-flex align-items-centre">
        {/* <NavNotice/> */}
        <NavAvatar/>
        {/* <NavMessage/> */}
    </ul>
</nav>
  )
}

export default Nav