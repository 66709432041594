import { useEffect, useRef } from "react";

function useIdleTimer(timeout, onIdle) {
  const timer = useRef(null);

  const resetTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      onIdle();
    }, timeout);
  };

  useEffect(() => {
    // Event listeners for user activity
    const events = ["mousemove", "keydown", "scroll", "click"];
    events.forEach((event) => window.addEventListener(event, resetTimer));

    // Start the timer
    resetTimer();

    return () => {
      // Cleanup event listeners and timer
      events.forEach((event) => window.removeEventListener(event, resetTimer));
      if (timer.current) clearTimeout(timer.current);
    };
  }, [timeout, onIdle]);

  return null;
}

export default useIdleTimer;
