import React, { useState } from "react";
import "./userformmodal.css";

function UserFormModal({ isVisible, onClose }) {
  const [formData, setFormData] = useState({
    name: "",
    clientid: "",
    password: "",
    apikey: "",
    secretkey: "",
    totpkey: "",
    accesstoken: "",
    monthlypnl: "0",
    todaypnl: "0",
    capital: "0",
    chatid: "",
    ishedge: true,
    daypnl: "0",
    profitsharing: "0",
    prevmonthloss: "0",
    sharingpercentage: "",
    optioncompounding: true,
    currentmonth: "0",
    publictoken: "1",
    // Hidden fields with default values
    nc0: "0",
    nc1: "0",
    nc2: "0",
    nc3: "0",
    nc4: "0",
    nc5: "0",
    nc6: "0",
    nc7: "0",
    nc8: "0",
    bnc0: "0",
    bnc1: "0",
    bnc2: "0",
    bnc3: "0",
    bnc4: "0",
    bnc5: "0",
    bnc6: "0",
    bnc7: "0",
    bnc8: "0",
    fnc0: "0",
    fnc1: "0",
    fnc2: "0",
    fnc3: "0",
    fnc4: "0",
    fnc5: "0",
    fnc6: "0",
    fnc7: "0",
    fnc8: "0",
    mnc0: "0",
    mnc1: "0",
    mnc2: "0",
    mnc3: "0",
    mnc4: "0",
    mnc5: "0",
    mnc6: "0",
    mnc7: "0",
    mnc8: "0",
    sc0: "0",
    sc1: "0",
    sc2: "0",
    sc3: "0",
    sc4: "0",
    sc5: "0",
    sc6: "0",
    sc7: "0",
    sc8: "0",
    xc0: "0",
    xc1: "0",
    xc2: "0",
    xc3: "0",
    xc4: "0",
    xc5: "0",
    xc6: "0",
    xc7: "0",
    xc8: "0",
    broker: "",
    server: "",
    status: true,
    priority: "",
    fullname: "",
    address: "",
    email: "",
    phone: "",
  });

  const [setIsCreated] = useState(false);
  const [setCreatedData] = useState(null);

  if (!isVisible) return null;

  const closeModal = () => {
    onClose();
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const fieldDisplayNames = {
    name: "Short Name",
    clientid: "Client ID",
    password: "Password",
    apikey: "API Key",
    secretkey: "Secret Key",
    totpkey: "TOTP Key",
    chatid: "Chat ID",
    sharingpercentage: "Sharing Percentage",
    broker: "Broker",
    server: "Server",
    priority: "Priority",
    fullname: "Full name",
    address: "Address",
    email: "E-Mail",
    phone: "Contact",
  };

  const requiredFields = [
    "name",
    "clientid",
    "password",
    "apikey",
    "secretkey",
    "totpkey",
    "chatid",
    "sharingpercentage",
    "broker",
    "server",
    "priority",
    "fullname",
    "address",
    "email",
    "phone",
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate required fields
    for (let field of requiredFields) {
      if (formData[field] === "" || formData[field] === undefined) {
        alert(`${fieldDisplayNames[field]} is required.`);
        return;
      }
    }

    console.log("Submitting form data:", formData);
    try {
      const response = await fetch(
        "https://www.quatdashboard.com:8084/api/mainTable/customers",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      if (response.ok) {
        const result = await response.json(); // Parse JSON response
        console.log("Customer created:", result);
        alert("Customer created successfully!");
      } else {
        console.error("Error:", await response.text());
        alert("Failed to create customer.");
      }
    } catch (error) {
      console.error("Network error:", error);
      alert("Network error occurred.");
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <span className="close-button" onClick={closeModal}>
          &times;
        </span>{" "}
        {/* Close button */}
        <h2>Create User</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-container">
            {requiredFields.map((field) => (
              <div className="form-group" key={field}>
                <label htmlFor={field}>{fieldDisplayNames[field]}:</label>
                {field === "broker" ? (
                  <select
                    id={field}
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                  >
                    <option value="">Select Broker</option>
                    <option value="zerodha">Zerodha</option>
                    <option value="flattrade">Flattrade</option>
                    <option value="tradier">Tradier</option>
                  </select>
                ) : field === "server" ? (
                  <select
                    id={field}
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                  >
                    <option value="">Select Server</option>
                    <option value="1">Server 1</option>
                    <option value="2">Server 2</option>
                    <option value="3">Server 3</option>
                  </select>
                ) : (
                  <input
                    type={field.startsWith("is") ? "checkbox" : "text"}
                    id={field}
                    name={field}
                    value={formData[field]}
                    checked={
                      field.startsWith("is") ? formData[field] : undefined
                    }
                    onChange={handleChange}
                  />
                )}
              </div>
            ))}
          </div>
          <div className="btn-container">
            <button type="submit" className="btn-submit">
              Create
            </button>
            <button type="button" className="btn-cancel" onClick={closeModal}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UserFormModal;
