import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import axios from "axios";

function ReportCharts() {
  const [data, setData] = useState({
    series: [],
    options: {
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      markers: {
        size: 4,
      },
      colors: ["#4154f1", "#2eca6a", "#ff771d"],
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.3,
          opacityTo: 0.4,
          stops: [0, 90, 100],
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      xaxis: {
        type: "datetime",
        categories: [],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  });

  useEffect(() => {
    axios
      .get("https://www.quatdashboard.com:8084/api/hourlypnl")
      .then((response) => {
        const responseData = response.data;

        // Group data by name
        const groupedData = responseData.reduce((acc, item) => {
          if (!acc[item.name]) {
            acc[item.name] = {
              name: item.name,
              data: [],
            };
          }
          acc[item.name].data.push(item.amount);
          return acc;
        }, {});

        const series = Object.values(groupedData);
        const categories = responseData.map((item) => item.id);

        setData((prevData) => ({
          ...prevData,
          series: series,
          options: {
            ...prevData.options,
            xaxis: {
              ...prevData.options.xaxis,
              categories: categories,
            },
          },
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <Chart
      options={data.options}
      series={data.series}
      type={data.options.chart.type}
      height={data.options.chart.height}
    />
  );
}

export default ReportCharts;
