import React, { useState, useEffect } from "react";
// import "./dashboard.css";
import Invoices from "./Invoices";
import SummaryCards from "./Summarycards";
function Paymentcard() {
  return (
    <section className="dashboard section">
      <div className="row">
        <div className="col-lg-8">
          <div className="row">
            <div className="col-12">
              <Invoices />
            </div>
            <div className="col-12">{/* <Tokenupdate /> */}</div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="row">
            <div className="col-12">
              <SummaryCards />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Paymentcard;
