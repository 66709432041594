import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Invoices.css";

const Invoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterStatus, setFilterStatus] = useState("All"); // State for dropdown filter

  const fetchInvoices = async () => {
    try {
      const response = await axios.get(
        "https://www.quatdashboard.com:8084/api/invoices/getcurrentmonth"
      );
      setInvoices(response.data);
      setFilteredInvoices(response.data); // Initially, display all invoices
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch invoices. Please try again.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  const getStatusClass = (status) => {
    switch (status) {
      case "Paid":
        return "status-paid";
      case "Pending":
        return "status-pending";
      case "Unpaid":
        return "status-unpaid";
      default:
        return "";
    }
  };

  // Handle dropdown filter
  const handleFilterChange = (event) => {
    const selectedStatus = event.target.value;
    setFilterStatus(selectedStatus);

    if (selectedStatus === "All") {
      setFilteredInvoices(invoices); // Show all invoices if "All" is selected
    } else {
      const filtered = invoices.filter(
        (invoice) => invoice.status === selectedStatus
      );
      setFilteredInvoices(filtered);
    }
  };

  if (loading) return <p>Loading invoices...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="invoices-container">
      <div className="invoice-header">
        <div>
          <h1>Invoices</h1>
          <p>There are {filteredInvoices.length} total invoices</p>
        </div>
        {/* 
        <div className="button-container">
          <button>Generate invoice</button>
        </div> */}
        <div className="filter-dropdown-container">
          <select
            id="status-filter"
            className="filter-dropdown"
            value={filterStatus}
            onChange={handleFilterChange}
          >
            <option value="All">All</option>
            <option value="Paid">Paid</option>
            <option value="Unpaid">Unpaid</option>
            <option value="Pending">Pending</option>
          </select>
        </div>
      </div>

      <div className="invoice-list-container">
        <div className="invoice-list">
          {filteredInvoices.map((invoice) => (
            <div key={invoice.id} className="invoice-item">
              <div className="invoice-id">{invoice.invoice}</div>
              <div className="invoice-details">
                <p>Due: {invoice.dueDate}</p>
              </div>
              <div className="invoice-name">{invoice.name}</div>
              <div className="invoice-amount">
                Rs {invoice.amount.toFixed(2)}
              </div>
              <div
                className={`invoice-status ${getStatusClass(invoice.status)}`}
              >
                {invoice.status}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Invoices;
